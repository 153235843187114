import React from 'react'
import {Link} from 'gatsby'

import "../../css/global.scss";
import "./ContactFooter.css";

import logo from './images/logo.svg';
import acc from './images/accredit.svg';
import fcircle from './images/fInCircle.svg';
import twcircle from './images/twInCircle.svg';
import igcircle from './images/igInCircle.svg';
import youcircle from './images/youInCircle.svg';

import Toggle from '../ToggleButton'; 

export default function ContactFooter({children, bHideSignup}){
    
    return(
        <div>
           <div className="minorFooter">
               <article className="topSchool">
                    <header><h2>Ranked Top 10 Worldwide</h2></header>
                    <div className="content">
                            <p>Industry websites such as Animation Career Review have consistently ranked us high in their annual lists of top animation schools in the world</p>

                            <a href="#">Learn about Top Worldwide schools</a>
                    </div>
                </article>
                
                <article className="contactfooter">
                    <div className="handle">
                        <h1>Be In The Know</h1>
                        <span class="action">Sign Up</span>

                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat<span></span></p>
                    
                        <form>
                            <div className="fields">
                                <div><input type="text" placeholder="Name*"/></div>
                                <div><input type="email" placeholder="Email*"/></div>
                                <div
                                    ><select>
                                        <option>Country*</option>
                                    </select>
                                </div>
                                <div>
                                    <select>
                                        <option>Program of Interest*</option>
                                    </select>
                                </div>
                            </div>

                            <div className="connectRadio">
                                <span>I would like connect with a Vanarts advisor.</span>
                                <Toggle className="toggleSwitch"/> 
                            </div>
                            
                            <Link to="#" className="cta-primary">SUBMIT</Link>

                        </form>
                    </div>
                </article>  
            </div>
            

            <footer className="footer">
                <div className="footerMajor">
                    <div className="minor">
                        <div>
                            <img src={logo} alt="VanArts Logo"/>
                            <p className="mission">Our mission is to unlock the creative and career potential within each student.</p>
                        </div>
                        <img src={acc} alt="Accreditations and Affiliations" id="affiliations"/>
                    </div>
                    <div className="details">
                        <div className="detail">
                            <h6>Address</h6>
                            <address>570 Dunsmuir Street, Suite 600<br/>Vancouver BC. Canada<br/>V6B 1Y1</address>
                        </div>
                        <div className="detail">
                            <h6>Phone</h6>
                            <span className="tel">604 682 ARTS(2787)</span>

                            <h6>Toll-Free</h6>
                            <span className="tel">800 396 ARTS(2787)</span>

                            <h6>Fax</h6>
                            <span className="tel">604 684 2789</span>
                        </div>
                        <div className="detail">
                            <h6>Hours</h6>
                            <div className="hours">
                                <span>Monday - Friday</span>
                                <span className="spec">9:00 - 16:30</span>
                            </div>
                        </div>
                        <div className="detail">
                            <a href="#" className="cta-primary">Request Info</a>
                            <a href="#" className="cta-primary">Apply Now</a>
                        </div>
                    </div>
                </div>
            
                <div className="contact-util">
                    <Link to="/contact" className="cta-primary">CONTACT US</Link>

                    <div className="socialLinks">
                        <img src={fcircle} alt="Facebook Icon"/>
                        <img src={twcircle} alt="Twitter Icon"/>
                        <img src={igcircle} alt="Instagram Icon"/>
                        <img src={youcircle} alt="Youtube Icon"/>
                    </div>
               
                    <p className="copy">&copy; 2021 Vancouver Institute of Media Arts. All Rights Reserved.</p>
                </div>
            </footer>
        </div>
    )
}