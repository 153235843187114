import React from "react"
import {Link} from "gatsby"
import "./WideMenu.css"

export default function WideMenu(){

    return (
        <div className="widemenu">
            <nav>
                <ul>
                    <li>
                        <a href="/">Programs &amp; Courses</a>
                        <section className="dropdown">
                            <div className="category">
                                <div className="title">
                                    <div>
                                        <h2>Full-Time Programs</h2>
                                        <p>Learn more about our 12 month full-time art programs.</p>
                                    </div>
                                </div>
                                <ul>
                                    <li><Link to="/programs/full-time/2d-character-animation/">2D Character Animation</Link></li>
                                    <li><Link to="/programs/full-time/2d-character-animation/">3D Character Animation</Link></li>
                                    <li><Link to="/programs/full-time/acting-film-television/">Acting For Film &amp; Television</Link></li>
                                    <li><Link to="/programs/full-time/broadcasting-online-media/">Broadcasting &amp; Online Media</Link></li>
                                    <li><Link to="/programs/full-time/game-art-design/">Game Art &amp; Design</Link></li>
                                    <li><Link to="/programs/full-time/professional-photography/">Professional Photography</Link></li>
                                    <li><Link to="/admissions/">Visual Effects For Film &amp; TV</Link></li>
                                    <li><Link to="/programs/full-time/web-development/">Web Development &amp; Interactive Design</Link></li>
                                </ul>

                                <div className="action">
                                    <Link to="/programs/full-time/" className="cta-primary">More About Full-Time</Link>
                                </div>
                            </div>
                            <div className="category">
                                <div className="title">
                                    <div>
                                        <h2>Part-Time Courses</h2>
                                        <p>Our part-time programs are scheduled with your time in mind</p>
                                    </div>
                                    
                                </div>
                                <ul>
                                    <li><Link to="/programs/part-time-courses/intro-acting/">Intro to Acting</Link></li>
                                    <li><Link to="/programs/part-time-courses/acting-film-tv-level-i/">Acting for Film &amp; TV Level 1</Link></li>
                                    <li><Link to="/programs/part-time-courses/digital-photography-101/">Digital Photography 101</Link></li>
                                    <li><Link to="/programs/part-time-courses/intro-maya/">Intro to Maya</Link></li>
                                    <li><Link to="/programs/part-time-courses/video-production/">Video Production</Link></li>
                                    <li><Link to="/programs/part-time-courses/acting-for-film-tv-level-ii-with-brent-stait/">Acting for Film &amp; TV Level 2</Link></li>
                                    <li><Link to="/programs/part-time-courses/acting-for-film-tv-level-iii/">Acting for Film &amp; TV Level 3</Link></li>
                                    <li><Link to="/programs/part-time-courses/audition/">The Audition with Richard Keats</Link></li>
                                    <li><Link to="/programs/part-time-courses/comedic-scene-study/">Scene Study Class</Link></li>
                                    <li><Link to="/programs/part-time-courses/illustrator/">Illustrator and Graphics</Link></li>
                                    <li><Link to="/programs/part-time-courses/photoshop/">Photoshop for Web Design</Link></li>
                                    <li><Link to="/programs/part-time-courses/reactjs/">ReactJS</Link></li>
                                </ul>

                                <div className="action">
                                    <Link to="/programs/part-time" className="cta-primary">More About Part-Time Courses</Link>
                                </div>
                            </div>
                            <div className="category">
                                <div className="title">
                                    <div>
                                        <h2>Summer Intensives</h2>
                                        <p>Summer art program courses are one week long intensives.</p>
                                    </div>
                                    
                                </div>
                                <ul>
                                    <li><Link to="/programs/summer-courses/2d-character-animation/">2D Character Animation</Link></li>
                                    <li><Link to="/programs/summer-courses/3d-character-animation/">3D Character Animation</Link></li>
                                    <li><Link to="/programs/summer-courses/acting-film-television/">Acting For Film &amp; Television</Link></li>
                                    <li><Link to="/programs/summer-courses/broadcasting-web-radio-tv/">Broadcasting &amp; Online Media</Link></li>
                                    <li><Link to="/programs/summer-courses/game-art-design/">Game Art &amp; Design</Link></li>
                                    <li><Link to="/programs/summer-courses/digital-photography/">Professional Photography</Link></li>
                                    <li><Link to="/programs/summer-courses/visual-effects/">Visual Effects For Film &amp; TV</Link></li>
                                    <li><Link to="/programs/summer-courses/web-development-interactive-design/">Web Development &amp; Interactive Design</Link></li>
                                </ul>

                                <div className="action">
                                    <Link to="/admissions" className="cta-primary">Find a Summer Course</Link>
                                </div>
                            </div>
                        </section>
                    </li>
                    <li>
                        <a href="/admissions">Admissions</a>
                        <ul id="programsList" className="subNav">
                            <li><Link to="/admissions/">Start Your Future</Link></li>
                            <li><Link to="/admissions/how-apply/">How to Apply</Link></li>
                            <li><Link to="/admissions/admission-requirements/">Admission Requirements</Link></li>
                            <li><Link to="/admissions/financial-aid-resources/">Financial Aid &amp; Resources</Link></li>
                            <li><Link to="/admissions/international-students/">International Students</Link></li>
                            <li><Link to="/admissions/admissions-india/">Admissions India</Link></li>
                            <li><Link to="/admissions/indigenous-admissions/">Indigenous Admissions</Link></li>
                            <li><Link to="/admissions/webinars/">Webinars</Link></li>
                            <li><Link to="/admissions/faqs/">FAQ</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/student-life/">Student Life</Link>
                    </li>
                    <li>
                        <Link to="/about-us/">About</Link>
                    </li>
                    <li>
                        <Link to="/">News &amp; Events</Link>
                    </li>
                    
                </ul>
            </nav>
        </div>
    )
}