import React, {useState} from 'react'
import { Link } from "gatsby"
import './PrimaryMenu.css'
import Logo from './images/Logo.svg'
import LogoWide from './images/vanarts-logo-wide.png'

const PrimaryMenu = ({nav}) => {
    
    // hide and show the flyout menu
    const [showMenu, setShowMenu] = useState(false)

    // show or hide the menu (toggle the showMenu: true, false)
    const toggleMenu = () => {
        setShowMenu( (showMenu) ? false: true)
    }

    const expandNav = e => {
        e.preventDefault();
        fadeOutOtherLinks();
        let subNav = e.target.nextElementSibling;
        let parentLinkElement = e.target.parentElement;
        parentLinkElement.className= "";
        subNav.style.display = "block";
        setTimeout(function(){
            subNav.style.opacity = 1;
        }, 50);
    }

    const fadeOutOtherLinks = e => {
        document.querySelectorAll("#flyout-menu li").forEach(item=>{
            item.classList.add("fade");
        })
    }

    return (
        <div id="menu-primary">
            <div id="menu-bar">
                <header>
                    <img src={Logo} alt="Vancouver Institute of Media Arts"  id="headerLogoSmall"/>
                    <img src={LogoWide} alt="Vancouver Institute of Media Arts"  id="headerLogoWide"/>
                </header>
                
                <div className="control" onClick={toggleMenu} role="button" tabIndex={0}>
                    {
                        !showMenu ?
                            <div className="burger">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        :
                            <div className="close">
                                <span>Close</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.749" height="24.75" viewBox="0 0 24.749 24.75">
                                    <g id="Group_768" data-name="Group 768" transform="translate(-20123.019 13590.643) rotate(90)">
                                        <path id="Path_304" data-name="Path 304" d="M0,0H27" transform="translate(-13587.814 -20125.848) rotate(-45)" fill="none" stroke="#cc0000" stroke-linecap="round" stroke-width="4"/>
                                        <path id="Path_466" data-name="Path 466" d="M0,0H27" transform="translate(-13568.723 -20125.848) rotate(-135)" fill="none" stroke="#cc0000" stroke-linecap="round" stroke-width="4"/>
                                    </g>
                                </svg>
                            </div>
                    }
                    
                </div>
            </div>
            {
            showMenu && 
            
            <div id="flyout">
                <div id="flyout-menu">
                    <nav>
                        <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <a href="/" onClick={e=>expandNav(e)}>Programs &amp; Courses</a>
                                <ul id="programsList" className="subNav">
                                    <li><Link to="/programs/full-time">Full-time Program</Link></li>
                                    <li><Link to="/programs/part-time">Part-time Program</Link></li>
                                    <li><Link to="/programs/summer">Summer Intensives</Link></li>
                                    <li><Link to="/programs/pathway-programs">Degree Pathways</Link></li>
                                </ul>
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <a href="/" onClick={e=>expandNav(e)}>Admissions</a>
                                <ul id="programsList" className="subNav">
                                    <li><Link to="/admissions/">Start Your Future</Link></li>
                                    <li><Link to="/admissions/how-apply/">How to Apply</Link></li>
                                    <li><Link to="/admissions/admission-requirements/">Admission Requirements</Link></li>
                                    <li><Link to="/admissions/financial-aid-resources/">Financial Aid &amp; Resources</Link></li>
                                    <li><Link to="/admissions/international-students/">International Students</Link></li>
                                    <li><Link to="/admissions/admissions-india/">Admissions India</Link></li>
                                    <li><Link to="/admissions/indigenous-admissions/">Indigenous Admissions</Link></li>
                                    <li><Link to="/admissions/webinars/">Webinars</Link></li>
                                    <li><Link to="/admissions/faqs/">FAQ</Link></li>
                                </ul>
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <Link to="/student-life/">Student Life</Link>
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <Link to="/about-us/">About</Link>
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <Link to="/">News &amp; Events</Link>
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16.737" height="16.737" viewBox="0 0 16.737 16.737">
                                <g id="Group_774" data-name="Group 774" transform="translate(-23837.422 -4628.22) rotate(135)" opacity="0.803">
                                    <path id="Path_304" data-name="Path 304" d="M0,0H13.737" transform="translate(-13587.814 -20135.227) rotate(-45)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                    <path id="Path_466" data-name="Path 466" d="M0,0H13.737" transform="translate(-13578.102 -20135.227) rotate(-135)" fill="none" stroke="#4cced1" stroke-linecap="round" stroke-width="3"/>
                                </g>
                                </svg>
                                <Link to="/">Contact Us</Link>
                            </li>
                            <li>
                                <svg id="Group_779" data-name="Group 779" xmlns="http://www.w3.org/2000/svg" width="16.827" height="16.849" viewBox="0 0 16.827 16.849">
                                <g id="Group_470" data-name="Group 470" transform="translate(8.553 0.118)">
                                    <path id="Path_573" data-name="Path 573" d="M51.6,4.8a5.754,5.754,0,0,1,5.765,5.747.8.8,0,0,0,.8.8h0a.8.8,0,0,0,.8-.8,7.326,7.326,0,0,0-2.146-5.215A7.505,7.505,0,0,0,51.6,3.2a.8.8,0,0,0,0,1.6Z" transform="translate(-50.8 -3.2)" fill="#4cced1"/>
                                </g>
                                <g id="Group_471" data-name="Group 471" transform="translate(8.553 3.187)">
                                    <path id="Path_574" data-name="Path 574" d="M51.6,22.1a2.725,2.725,0,0,1,2.714,2.714.8.8,0,1,0,1.6,0A4.3,4.3,0,0,0,51.6,20.5a.8.8,0,1,0,0,1.6Z" transform="translate(-50.8 -20.5)" fill="#4cced1"/>
                                </g>
                                <g id="Group_472" data-name="Group 472">
                                    <path id="Path_575" data-name="Path 575" d="M18.8,14.766l-3.441-1.774a1.148,1.148,0,0,0-1.419.3L12.68,14.908a12.7,12.7,0,0,1-3.246-2.377,12.816,12.816,0,0,1-2.412-3.3l1.6-1.242a1.126,1.126,0,0,0,.3-1.419L7.163,3.147a1.134,1.134,0,0,0-1.632-.426l-2.2,1.455A1.726,1.726,0,0,0,2.586,5.7a11.253,11.253,0,0,0,.266,1.951,16.238,16.238,0,0,0,4.311,7.131,16.119,16.119,0,0,0,7.131,4.328,13.2,13.2,0,0,0,1.969.266,1.727,1.727,0,0,0,1.543-.781l1.419-2.217A1.121,1.121,0,0,0,18.8,14.766Z" transform="translate(-2.583 -2.532)" fill="#4cced1"/>
                                </g>
                                </svg>

                                <Link to="/">Call Us</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            }

        </div>
    )
}

export default PrimaryMenu;