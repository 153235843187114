import React from "react";
import {Link} from "gatsby";

import "./StickyActions.css";
import "../../css/global.scss";

export default function StickyActions(){

    return (
        <div id="action-footer">
            <Link to="#" className="cta-primary">Request Info</Link>
            <Link to="#" className="cta-primary" id="attendWebinarButton">Attend a Webinar</Link>
            <Link to="#" className="cta-secondary">Apply Now</Link>
        </div>
    );
}
