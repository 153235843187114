import React from 'react';
import { Helmet } from 'react-helmet'
import PrimaryMenu from "./PrimaryMenu";
import WideMenu from "./WideMenu";
import StickyActions from "./StickyActions";
import '../css/global.scss'
import ContactFooter from '../components/ContactFooter';

const Layout = ({children, seo, nav}) =>{
    return (
        <main>
            {seo && 
            <Helmet>
                <html lang="en" />
                <title>{seo.title}</title>
                <meta name="google-site-verification" content="T3C-PtDK2pYau9-zkEB4L35V5u26rCz2mtw-N_Cygp8" />
                <meta name="description" content={seo.description} />
            </Helmet>
            }
            
            <PrimaryMenu nav={nav}/>
            <WideMenu nav={nav} />

            <section id="maincontent">
                {children}
                <ContactFooter/>
            </section>
            
            <StickyActions/>
        </main>
    )
}

export default Layout;