import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './ToggleButton.css';

Toggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool
}

export default function Toggle(props) {
  const toggle = useRef()
  const checkbox = useRef()
  //console.log(props);
  
  function slide() {
    if (props.onChange) props.onChange()
    toggle.current.classList.toggle('selected')
    checkbox.current.checked = !checkbox.current.checked
  }

  return (
    <div>
      <input
        ref={checkbox}
        name={props.name}
        className='switch-checkbox'
        type='checkbox'
        defaultChecked={props.value}
        value={props.value || false} />
      <span
        ref={toggle}
        onClick={slide}
        className={props.checked ? 'selected switch' : 'switch'} >
        <span className='slider'></span>
      </span>
    </div>
  )
}